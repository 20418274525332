




















import Vue from "vue";
// import { defineComponent } from "vue";

export default Vue.extend({
    data() {
        return {
            pages: [
                {
                    name: "Users",
                    route: "users"
                },
                {
                    name: "Sessions",
                    route: "livestreams"
                },
                {
                    name: "Speakeasies",
                    route: "speakeasies"
                },
                {
                    name: "Appointments",
                    route: "appointments"
                },
                {
                    name: "Exhibitors",
                    route: "exhibitors"
                },
                {
                    name: "Posters",
                    route: "posters"
                },
                {
                    name: "Favorites",
                    route: "favorites"
                },
                {
                    name: "Messages",
                    route: "messages"
                },
                {
                    name: "Session Evaluations",
                    route: "session-evals"
                },
                {
                    name: "Poll Questions",
                    route: "polls"
                },
                {
                    name: "Question of the Day",
                    route: "qotd"
                }
            ]
        };
    }
});
